import loadable from '@loadable/component';
import { ImageQuery } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import fetch from 'node-fetch';
import React, { useEffect, useState } from 'react';
import { Layout } from '../components/layout';
import { Reviews as ReviewsComponent } from '../components/reviews-featured';
import { Intro } from '../components/reviews/intro';

const Divider = loadable(() => import('../components/divider'), {
  resolveComponent: (components) => components.Divider
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

const SectionLabel = loadable(() => import('../components/section-label'), {
  resolveComponent: (components) => components.SectionLabel
});

const ResponsiveSpacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.ResponsiveSpacer
});

const ReviewsFeatured = loadable(() => import('../components/reviews-featured'), {
  resolveComponent: (components) => components.ReviewsFeatured
});

const ReviewsWrapper = loadable(() => import('../components/reviews/reviews-wrapper'), {
  resolveComponent: (components) => components.ReviewsWrapper
});

export const query = graphql`
  {
    images: allFile(filter: { relativePath: { regex: "/reviews//" }, extension: { ne: "svg" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export const Reviews = ({ data }: { data: ImageQuery }) => {
  const [bottomLine, setBottomLine] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);

  const fetchPage = async (page: number) => {
    const url = `https://api.yotpo.com/v1/widget/${process.env.YOTPO_APP_KEY}/products/yotpo_site_reviews/reviews.json?per_page=9&page=${page}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.response.reviews);
        setBottomLine(data.response.bottomline.total_review);
      })
      .catch((_) => null); // no-op
  };

  useEffect(() => {
    // The effect callback type will not accept a promise of void
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPage(currentPage);
  }, [currentPage]);

  return (
    <Layout>
      <Intro imageData={data} />
      <ResponsiveSpacer />
      <Divider />
      <ResponsiveSpacer />
      <SectionLabel>HEAR FROM OUR CUSTOMERS</SectionLabel>
      <ReviewsFeatured>
        <ReviewsComponent imageData={data} />
      </ReviewsFeatured>
      <Divider />
      <ReviewsWrapper
        bottomLine={bottomLine}
        currentPage={currentPage}
        reviews={reviews}
        setCurrentPage={setCurrentPage}
      />
      <Spacer />
    </Layout>
  );
};

export default Reviews;
