import { getImage, ImageQuery } from '@rategravity/marketing-components';
import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpHeader,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { GreyFieldWithPhoto } from '../../grey-field-with-photo';
import { HighlightAlt, PrimaryText, SectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingBottom: 20
});

const Section = createOwnUpComponent('section', SectionStyle);

const Label = styled(OwnUpHeader)`
  font-size: 32px;
  background-color: ${colors.MINT};
  padding: 14px;
  max-width: fit-content;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 36px;
  ${buildMediaQueries('xsmall', {
    fontSize: '21px'
  })}
`;

const ContentWrapperStyle = createOwnUpStyle({
  display: 'flex',
  variants: {
    smallAndDown: {
      display: 'block'
    }
  }
});

const ContentWrapper = createOwnUpComponent('div', ContentWrapperStyle);

const Text1Wrapper = createOwnUpStyle({
  width: '50%',
  padding: '30px 30px 0 60px',
  variants: {
    smallAndDown: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 30px 0',
      margin: 'auto',
      wordBreak: 'break-word'
    }
  }
});

const Text1 = createOwnUpComponent('div', Text1Wrapper);

const Text2Wrapper = createOwnUpStyle({
  width: '50%',
  padding: '30px 60px 0 30px',
  variants: {
    smallAndDown: {
      width: '100%',
      boxSizing: 'border-box',
      padding: '10px 30px 0',
      margin: 'auto'
    }
  }
});

const Text2 = createOwnUpComponent('div', Text2Wrapper);

const DividerStyle = createOwnUpStyle({
  backgroundColor: colors.PRIMARY,
  height: 2,
  margin: '30px 0',
  width: 50
});

const Divider = createOwnUpComponent('div', DividerStyle);

const GreyFieldText = styled(OwnUpText)`
  ${fonts.GRAPHIK_REGULAR}
  font-family: 'Graphik-Regular', 'Arial', 'sans-serif';
  font-size: 29px;
  ${buildMediaQueries('xsmall', {
    fontSize: '18px'
  })}
`;

const greyFieldContent = (
  <>
    <SectionHeader>
      “They got us the pre-approval in like 10 minutes! They absolutely saved the day.”
    </SectionHeader>
    <Divider />
    <GreyFieldText>Emily and Evan, Peabody MA</GreyFieldText>
  </>
);

export const imgStyle = {
  height: '100%',
  margin: 'auto',
  position: 'relative' as const
};

const EmilyAndEvanImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'emily-and-evan.png', metadata, imgStyle, true);

export const Intro = ({ imageData }: { imageData: ImageQuery }) => {
  return (
    <Section aria-label="Featured customer success story">
      <Label variant="title">The Homeowners</Label>
      <GreyFieldWithPhoto content={greyFieldContent}>
        <EmilyAndEvanImage imageData={imageData} />
      </GreyFieldWithPhoto>
      <ContentWrapper>
        <Text1>
          <PrimaryText>
            After years of renting, Emily and Evan decided it was time to put down roots. But when
            they wanted to make a last-minute offer on their dream home, they couldn’t get a hold of
            anyone at their mortgage company. Their realtor recommended Own Up, and thanks to our
            innovative pre-qualification technology (a mortgage industry first), they had the
            pre-approval letter they needed within the hour.
          </PrimaryText>
        </Text1>
        <Text2>
          <PrimaryText>
            “Everyone was so kind, so patient, so informative, and really just warm. They gave me so
            much confidence.”{' '}
            <HighlightAlt>
              Own Up matched them to a lender at a preferred interest rate, saving them $38,000 over
              the life of their loan.
            </HighlightAlt>{' '}
            “Own Up was a champion for us throughout the entire process … I can’t describe what
            saving that much money means to us!
          </PrimaryText>
        </Text2>
      </ContentWrapper>
    </Section>
  );
};
